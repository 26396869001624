@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@600&family=Lato:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@600&family=Lato:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

body {
}
.App {
  /* background: 'linear-gradient(219deg, #EBEBEF -11.5%, #F9F9FA 31.72%, rgba(255, 255, 255, 0.90) 32.22%, rgba(240, 241, 241, 0.02) 40.58%, rgba(245, 246, 246, 0.14) 57.06%, #D7D7D7 115.43%)'; */
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.page-filters-tabs .MuiTabs-indicator {
  display: none !important;
}
.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between;
}
::-webkit-scrollbar {
  display: none;
}
/* .MuiBox-root.css-bg6w1z {
  padding: '10px';
} */
.MuiBox-root.css-1mvlbc9 {
  padding: 0px;
}
.rpv-default-layout__container {
  max-height: 90%;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-x76uav-MuiGrid-root {
  padding: 0;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-128ceud-MuiGrid-root {
  padding: 0px;
}
/* .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-6.MuiGrid-grid-lg-6.MuiGrid-grid-xl-6.css-1k7sk4d-MuiGrid-root {
  padding: 0px;
} */
.css-128ceud-MuiGrid-root.MuiGrid-item {
  padding: 0px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-b07vn0-MuiPaper-root-MuiAccordion-root::before {
  height: 0px;
}
/* sidebar of pdf view list */
.rpv-default-layout__sidebar-headers {
  display: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -o-transform: scale(1.02);
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typewriter {
  overflow: hidden;
  white-space: pre-wrap;
  animation: typewriter 2s steps(40) 1 normal both;
}
