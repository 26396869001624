.react-pdf__Page__canvas {
  display: block;
  user-select: none;
  width: 100% !important;
  height: 100% !important;
}
@media screen and (min-width: 900px) and (max-width: 1000px) {
  .react-pdf__Page__canvas {
    width: 135% !important;
    height: 150% !important;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .react-pdf__Page__canvas {
    width: 110% !important;
    height: 110% !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
